import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  Container,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Rating from "@mui/material/Rating";
import CHECKCIRCLE from "../../../../../../../images/check_circle.svg";
import InfoBox from "../../../../../../../components/infobox";
import SELECTLENDER from "../../../../../../../images/selectlender1.svg";
import apiEndPointsConfig from "../../../../../../../config/apiEndPointConfig";
// import { useApiCallMutation } from "../../../../../../services/apiCallServices";
import { useLocation, useNavigate } from "react-router-dom";
import CasheLogo from "../../../../../../../Leanders/bhanix-finance-logoas.png";
import FaircentLogo from "../../../../../../../Leanders/faircent.png";
import FibeLogo from "../../../../../../../Leanders/fibe.png";
import KisshtLogo from "../../../../../../../Leanders/kissht.png";
import Kreditbee from "../../../../../../../Leanders/kreditbee.png";
import LendingkartLogo from "../../../../../../../Leanders/lendingkart.png";
import lnt from "../../../../../../../Leanders/lnt_logo.png";
import LoantapLogo from "../../../../../../../Leanders/loantap.png";
import MoneytapLogo from "../../../../../../../Leanders/moneytap.png";
import MoneyviewLogo from "../../../../../../../Leanders/moneyview.png";
import MoneywideLogo from "../../../../../../../Leanders/moneywide.png";
import MpocketLogo from "../../../../../../../Leanders/mpocket.png";
import NiraLogo from "../../../../../../../Leanders/nira.png";
import PaysenseLogo from "../../../../../../../Leanders/paysense.png";
import ProtiumLogo from "../../../../../../../Leanders/protium.png";
import StashfinLogo from "../../../../../../../Leanders/stashfin.png";
import ZypeLogo from "../../../../../../../Leanders/zype.png";
import Indifi from "../../../../../../../Leanders/indifi.png";
import Truebalance from "../../../../../../../Leanders/truebalance.png";
import Incred from "../../../../../../../Leanders/incred.svg";
import Upwards from "../../../../../../../Leanders/upwards.png";
import prefr from "../../../../../../../Leanders/prefr.webp";
import fatakPay from "../../../../../../../Leanders/fatak_pay.png";
import abfl from "../../../../../../../Leanders/abfl.png";
import privo from "../../../../../../../Leanders/privoLogo.png";
import sbl from "../../../../../../../Leanders/sbl.png";
import olyv from "../../../../../../../Leanders/olyv.png";
import abhiLoan from "../../../../../../../Leanders/abhiLoan.png";
import tataCapital from "../../../../../../../Leanders/tata-logo-blue.svg";
import { useDispatch, useSelector } from "react-redux";
import { useApiCallMutation } from "../../../../../../../services/apiCallServices";
import { setFormData, setIsLoading } from "../../../../../../../store/reducers";
import IncredMP from "../incred_mp";
import lendenClub from "../../../../../../../Leanders/lenden.png";

const TextStyling = {
  fontSize: "10px",
  fontFamily: "Inter,sans-serif",
  color: "#262250",
  mt: "10px",
  textAlign: "left",
};

const Marketplace = ({ updateTab, setLeadStatus }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [LenderDetails, setLenderDetails] = useState();
  const [incorrectId, setIncorrectId] = useState(false);
  const isLoading = useSelector((state) => state?.form?.isLoading);
  const [timer, setTimer] = useState(45);
  const [incredPage, setIncredPage] = useState(false);
  const [LeadStatusApi, leadStatusApiData] = useApiCallMutation();
  const TimerCheck = sessionStorage.getItem("timerCheck") || "";
  useEffect(() => {
    if (TimerCheck && timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          const newTimer = prevTimer - 1;
          if (newTimer % 15 === 0) {
            handleLoadMore();
          }
          return newTimer;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timer]);
  //lead status api call
  const handleLoadMore = () => {
    const data = {
      leadId: sessionStorage?.getItem("tempLead"),
    };
    LeadStatusApi({
      endPoint: apiEndPointsConfig?.LeadStatus,
      method: "POST",
      data: { data },
    });
  };
  useEffect(() => {
    if (leadStatusApiData?.isSuccess) {
      const response = JSON.stringify(leadStatusApiData?.data);
      sessionStorage.setItem("Lenders", response);
      setLenderDetails(leadStatusApiData?.data);
    }
  }, [leadStatusApiData?.isSuccess]);

  useEffect(() => {
    if (sessionStorage.getItem("Lenders") !== undefined) {
      setLenderDetails(JSON.parse(sessionStorage.getItem("Lenders")));
    }
  }, []);

  //GetLenderLeadPublibStatus api call
  const [GetLenderPublicLeadApi, getLenderPublicLeadApiData] =
    useApiCallMutation();
  useEffect(() => {
    if (getLenderPublicLeadApiData?.isSuccess) {
      dispatch(setIsLoading(true));
      const employmentType =
        getLenderPublicLeadApiData?.data?.data?.employmentType;
      sessionStorage.setItem("employmentType", employmentType);
      if (getLenderPublicLeadApiData?.data?.status?.code === 404) {
        setIncorrectId(true);
      }
      if (getLenderPublicLeadApiData?.data?.data?.status?.length === 0) {
        sessionStorage.setItem("leadEligibility", "googleExitPage");
        setLeadStatus("false");
      }
      const response = JSON.stringify(getLenderPublicLeadApiData);
      sessionStorage.setItem("Lenders", response);
      setLenderDetails(getLenderPublicLeadApiData?.data);
      dispatch(setIsLoading(false));
    }
  }, [getLenderPublicLeadApiData?.isSuccess]);

  const getLendersPublicLeadStatus = (leadId) => {
    let data = {};
    if (leadId) {
      data = {
        leadId: leadId,
      };
    } else {
      data = {
        leadId:
          sessionStorage?.getItem("publicLeadId") !== undefined
            ? sessionStorage?.getItem("publicLeadId")
            : "",
      };
    }

    GetLenderPublicLeadApi({
      endPoint: apiEndPointsConfig?.PublicLeadStatus,
      method: "POST",
      data: { data },
    });
  };
  useEffect(() => {
    if (LenderDetails?.data?.data?.status?.length === 0) {
      sessionStorage.setItem("leadEligibility", "googleExitPage");
    }
    const publicleadId =
      sessionStorage?.getItem("publicLeadId") !== undefined
        ? sessionStorage?.getItem("publicLeadId")
        : "";
    if (publicleadId || location.state?.from === "otp") {
      getLendersPublicLeadStatus(location.state?.leadId);
    }
  }, []);

  useEffect(() => {
    // getLeadDetails();
    if (sessionStorage.getItem("tempLead")) {
      MarketPlaceImpressionAsync();
    }
  }, [LenderDetails]);

  //marketplace impression api call
  const [MarketPlaceImpressionApi, marketPlaceImpressionApiData] =
    useApiCallMutation();
  const MarketPlaceImpressionAsync = () => {
    const data = {
      leadId: sessionStorage.getItem("tempLead"),
    };
    MarketPlaceImpressionApi({
      endPoint: apiEndPointsConfig?.MarketPlaceImpression,
      method: "POST",
      data: { data },
    });
  };

  //marketplace click api call
  const [MarketPlaceClickApi, marketPlaceClickApiData] = useApiCallMutation();
  const MarketplaceClickAsync = async (
    LenderId,
    redirectionLink,
    lowercaseName
  ) => {
    const data = {
      lenderId: LenderId,
      leadId: sessionStorage.getItem("tempLead"),
    };
    MarketPlaceClickApi({
      endPoint: apiEndPointsConfig?.MarketplaceClick,
      method: "POST",
      data: { data },
    });
    if (lowercaseName === "incred") {
      sessionStorage.setItem("incred_link", redirectionLink);
      setIncredPage(true);
    } else {
      window.open(redirectionLink, "_blank");
    }
  };

  const applyNow = (lenderId, redirectionLink, name) => {
    const lowercaseName = name?.toLowerCase();
    const accessToken = sessionStorage.getItem("accessToken")
      ? sessionStorage.getItem("accessToken")
      : "undefined";
    if (accessToken !== "undefined") {
      MarketplaceClickAsync(lenderId, redirectionLink, lowercaseName);
    } else {
      navigate("/login");
      // setLenderData({
      //   lenderId: lenderId,
      //   redirectionLink: redirectionLink,
      // });
    }
  };

  const logo = (lender) => {
    const lowercaseName = lender?.toLowerCase();
    return lowercaseName === "cashe"
      ? CasheLogo
      : lowercaseName === "mpocket"
      ? MpocketLogo
      : lowercaseName === "fibe"
      ? FibeLogo
      : lowercaseName === "kreditbee"
      ? Kreditbee
      : lowercaseName === "moneytap"
      ? MoneytapLogo
      : lowercaseName === "lendingkart"
      ? LendingkartLogo
      : lowercaseName === "paysense"
      ? PaysenseLogo
      : lowercaseName === "moneyview"
      ? MoneyviewLogo
      : lowercaseName === "loantap"
      ? LoantapLogo
      : lowercaseName === "zype"
      ? ZypeLogo
      : lowercaseName === "moneywide"
      ? MoneywideLogo
      : lowercaseName === "stashfin"
      ? StashfinLogo
      : lowercaseName === "faircent"
      ? FaircentLogo
      : lowercaseName === "nira"
      ? NiraLogo
      : lowercaseName === "kissht"
      ? KisshtLogo
      : lowercaseName === "protium"
      ? ProtiumLogo
      : lowercaseName === "indifi"
      ? Indifi
      : lowercaseName === "truebalance"
      ? Truebalance
      : lowercaseName === "incred"
      ? Incred
      : lowercaseName === "upwards"
      ? Upwards
      : lowercaseName === "prefr"
      ? prefr
      : lowercaseName === "fatakpay"
      ? fatakPay
      : lowercaseName === "abfl"
      ? abfl
      : lowercaseName === "privo"
      ? privo
      : lowercaseName === "sbl"
      ? sbl
      : lowercaseName === "olyv"
      ? olyv
      : lowercaseName === "l&t"
      ? lnt
      : lowercaseName === "abhiloan"
      ? abhiLoan
      : lowercaseName === "lendenclub"
      ? lendenClub
      : lowercaseName === "tatacapital"
      ? tataCapital
      : "";
  };

  const isMobile = useMediaQuery("(max-width: 768px)");
  const isScreen = useMediaQuery("(max-width: 1200px)");
  return (
    <>
      {incredPage ? (
        <IncredMP setIncredPage={setIncredPage} />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "center",
            // gap: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Container maxWidth="sm">
              <Box
                sx={{
                  bgcolor: "#EBEFF8",
                  borderRadius: "10px",
                  padding: "25px 20px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "20px",
                  alignItems: "center",
                  textAlign: "center",
                  color: "#3B5BBA",
                }}
              >
                <Typography sx={{ fontSize: "15px" }}>
                  {TimerCheck && timer
                    ? "We're currently pairing you with the best lenders. This could take a minute—thanks for your patience!"
                    : "Thanks for hanging in there! We've matched you with potential lenders. Review your options and find the perfect loan for you today."}
                </Typography>
                {TimerCheck && timer > 0 && <span>00:{timer}</span>}
              </Box>
            </Container>
          </Box>
          <Box sx={{ width: "100%" }}>
            {incorrectId === true ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "60px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "#3653A9",
                    lineHeight: "24px",
                    mb: 4,
                  }}
                >
                  The provided lead id is incorrect, Please provide the correct
                  Lead ID
                </Typography>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100% ",
                    textAlign: "center",
                    mt: 6,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#343435B2",
                      lineHeight: "24px",
                      mb: 4,
                    }}
                  >
                    Your loan has been approved with the following lenders
                  </Typography>
                  <Divider
                    sx={{
                      width: "80%",
                      borderWidth: "2px",
                      color: "#627CC8",
                      fontWeight: 700,
                      fontSize: "20px",
                      lineHeight: "30px",
                    }}
                  >
                    Best Match
                  </Divider>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {isLoading ? (
                    <CircularProgress />
                  ) : LenderDetails?.data?.status?.length > 0 ? (
                    <>
                      <Grid
                        sx={{
                          border: "1px solid #E1E1E1",
                          borderRadius: "10px",
                          boxShadow: "0 3px 6px #0000001c",
                          display: "flex",
                          flexDirection: { xs: "column", lg: "row" },
                          marginBottom: "30px",
                          alignItems: { xs: "center" },
                          justifyContent: { xs: "center" },
                          width: {
                            xs: "100%",
                            sm: "80%",
                            md: "90%",
                            lg: "80%",
                          },
                          textAlign: "center",
                          mt: "50px",

                          placeItems: "center",
                          padding: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            width: {
                              xs: "50%",
                              sm: "50%",
                              md: "40%",
                              lg: "20%",
                            },
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",

                            height: { xs: "20%", lg: "90%" },
                          }}
                        >
                          <Typography
                            style={{
                              width: "70%",
                              height: "auto",
                              fontSize: "26px",
                              fontWeight: "300",
                            }}
                          >
                            Svatantr
                          </Typography>
                        </Box>

                        <Divider
                          orientation={isScreen ? "horizontal" : "vertical"}
                          flexItem
                          sx={{ m: "10px" }}
                        />
                        <Box
                          sx={{
                            width: isMobile ? "100% " : "35%",
                            display: "flex",
                            // alignItems: "center",
                            // ml: { lg: 1 },
                            // justifyContent: "center",
                            flexDirection: "column",
                            // borderRight: "1px solid #E1E1E1",
                            height: isMobile ? "100%" : "80%",
                            // border: "2px solid red",
                          }}
                        >
                          <Box textAlign={"left"}>
                            <Box
                              // key={index}
                              sx={{
                                display: "flex",
                                mt: 1,
                                //   border: "2px solid red"
                              }}
                            >
                              <img
                                style={{ marginRight: "3px" }}
                                src={CHECKCIRCLE}
                                alt="checked icon"
                              />
                              <Typography
                                sx={{
                                  ...(isMobile
                                    ? {
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        mt: 0,
                                        ml: 1,
                                      }
                                    : TextStyling),
                                  mt: 0,
                                }}
                              >
                                Low Interest Rate
                              </Typography>
                            </Box>
                            <Box
                              // key={index}
                              sx={{
                                display: "flex",
                                mt: 1,
                                //   border: "2px solid red"
                              }}
                            >
                              <img
                                style={{ marginRight: "3px" }}
                                src={CHECKCIRCLE}
                                alt="checked icon"
                              />
                              <Typography
                                sx={{
                                  ...(isMobile
                                    ? {
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        mt: 0,
                                        ml: 1,
                                      }
                                    : TextStyling),
                                  mt: 0,
                                }}
                              >
                                Disbursal in 24 hours
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        {!isMobile && (
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ m: "10px" }}
                          />
                        )}
                        <Box
                          sx={{
                            mt: isMobile ? "10px" : "0px",
                            width: isMobile ? "100% " : "35%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            height: isMobile ? "100%" : "80%",
                            // border: "2px solid red",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: 700,
                                whiteSpace: "nowrap",
                              }}
                            >
                              4 / 5
                            </Typography>
                            <Rating
                              name="read-only"
                              value={4}
                              readOnly
                              size={isMobile ? "medium" : "small"}
                            />
                          </Box>
                          <Button
                            sx={{
                              backgroundColor: "#3B5BBA",
                              fontSize: "10px",
                              whiteSpace: "nowrap",
                            }}
                            variant="contained"
                            onClick={(e) => {
                              e.preventDefault();
                              window.location.href =
                                "https://app.svatantr.in/#/external/apply?ref=67065208ac381c6fdc698046&pr=cc";
                            }}
                          >
                            Apply Now
                          </Button>
                          {/* </Box> */}
                        </Box>
                      </Grid>
                      {LenderDetails?.data?.status?.map((val) => {
                        const ratingValue = val?.rating.split(" ")[0];
                        return (
                          <Grid
                            key={val.id}
                            sx={{
                              border: "1px solid #E1E1E1",
                              borderRadius: "10px",
                              boxShadow: "0 3px 6px #0000001c",
                              display: "flex",
                              flexDirection: { xs: "column", lg: "row" },
                              marginBottom: "30px",
                              alignItems: { xs: "center" },
                              justifyContent: { xs: "center" },
                              width: {
                                xs: "100%",
                                sm: "80%",
                                md: "90%",
                                lg: "80%",
                              },
                              textAlign: "center",
                              mt: "50px",

                              placeItems: "center",
                              padding: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                width: {
                                  xs: "50%",
                                  sm: "50%",
                                  md: "40%",
                                  lg: "20%",
                                },
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",

                                height: { xs: "20%", lg: "90%" },
                              }}
                            >
                              <img
                                style={{ width: "70%", height: "auto" }}
                                // src={MoneytapLogo}
                                src={logo(val.name)}
                                alt="Logo"
                              />
                            </Box>

                            <Divider
                              orientation={isScreen ? "horizontal" : "vertical"}
                              flexItem
                              sx={{ m: "10px" }}
                            />
                            <Box
                              sx={{
                                width: isMobile ? "100% " : "35%",
                                display: "flex",
                                // alignItems: "center",
                                // ml: { lg: 1 },
                                // justifyContent: "center",
                                flexDirection: "column",
                                // borderRight: "1px solid #E1E1E1",
                                height: isMobile ? "100%" : "80%",
                                // border: "2px solid red",
                              }}
                            >
                              <Box textAlign={"left"}>
                                {val.features?.map((list) => (
                                  <Box
                                    // key={index}
                                    sx={{
                                      display: "flex",
                                      mt: 1,
                                      //   border: "2px solid red"
                                    }}
                                  >
                                    <img
                                      style={{ marginRight: "3px" }}
                                      src={CHECKCIRCLE}
                                      alt="checked icon"
                                    />
                                    <Typography
                                      sx={{
                                        ...(isMobile
                                          ? {
                                              fontSize: "12px",
                                              fontWeight: 400,
                                              mt: 0,
                                              ml: 1,
                                            }
                                          : TextStyling),
                                        mt: 0,
                                      }}
                                    >
                                      {list.title}
                                    </Typography>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                            {!isMobile && (
                              <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ m: "10px" }}
                              />
                            )}
                            <Box
                              sx={{
                                mt: isMobile ? "10px" : "0px",
                                width: isMobile ? "100% " : "35%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                height: isMobile ? "100%" : "80%",
                                // border: "2px solid red",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  mb: 2,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: 700,
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {val?.rating}
                                </Typography>
                                <Rating
                                  name="read-only"
                                  value={ratingValue}
                                  readOnly
                                  size={isMobile ? "medium" : "small"}
                                />
                              </Box>
                              <Button
                                sx={{
                                  backgroundColor: "#3B5BBA",
                                  fontSize: "10px",
                                  whiteSpace: "nowrap",
                                }}
                                variant="contained"
                                onClick={(e) => {
                                  e.preventDefault();
                                  applyNow(
                                    val?.lenderId,
                                    val?.redirectionLink,
                                    val?.name
                                  );
                                }}
                              >
                                Apply Now
                              </Button>
                              {/* </Box> */}
                            </Box>
                          </Grid>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              </>
            )}
          </Box>
          <Box>
            <Box display={"flex"} gap={"5px"} margin={"8px"}>
              {/* <InfoBox
            iconSrc={process.env.PUBLIC_URL + "/assets/Icons/lock.svg"}
            text="Your data is securely encrypted. We never share it with third parties. See our"
            boldText="Privacy Policy ."
          /> */}
            </Box>
            {/* <Box display={"flex"} gap={"5px"} margin={"8px"}>
              <InfoBox
                iconSrc={
                  process.env.PUBLIC_URL + "/assets/Icons/readiness_score.svg"
                }
                text="No impact to your credit score"
              />
            </Box> */}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Marketplace;
